/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */
import { useState } from "react";
import { ButtonBack, ButtonNext, Slider, DotGroup, ButtonPlay } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import { CarouselProvider } from '@/components/blocks/Carousel/CarouselProvider';
import { CarouselSlide } from '@/components/blocks/Carousel/CarouselSlide';
import { carouselProviderSX } from '@/components/blocks/HeroCarousel/styles/carouselProvider';
import { carouselSlideSX } from '@/components/blocks/Carousel/styles/carouselSlide';
import { CarouselOptions } from '@/data/types/Carousel';
import { Grid, SxProps, Theme, Typography } from '@mui/material';
import { AriaAttributes } from 'react';

type HeroSliderProps = {
    a11yProps?: AriaAttributes[];
    slides: JSX.Element[];
    carouselProps?: CarouselOptions;
    carouselSlideStyles?: SxProps<Theme>;
};

const defaultCarouselProps = {
    naturalSlideWidth: 248,
    naturalSlideHeight: 300,
    visibleSlides: 1,
    step: 1,
    infinite: true,
    dragEnabled: false,
    totalSlides: 1,
    isIntrinsicHeight: true,
};

const EMPTY_A11_PROPS: AriaAttributes[] = [];
export const HeroSlider = ({
    a11yProps = EMPTY_A11_PROPS,
    slides,
    carouselProps,
    carouselSlideStyles = carouselSlideSX,
}: HeroSliderProps) => {
    const [playing, setPlaying] = useState(true)
    const mergedProps = { ...defaultCarouselProps, ...carouselProps };

    return (
        <CarouselProvider {...mergedProps} totalSlides={slides.length} sx={carouselProviderSX}>
            <Slider>
                {slides.map((slide: JSX.Element, i: number) => (
                    <CarouselSlide
                        index={i}
                        key={slide.key}
                        sx={carouselSlideStyles}
                        {...(a11yProps[i] ?? {})}
                    >
                        {slide}
                    </CarouselSlide>
                ))}
            </Slider>

            {slides && slides?.length !== 0 ? (
                <Grid container justifyContent="center" alignItems="center" gap="1" sx={{ marginTop: { lg: '24px', xs: '12px' } }}>
                    {mergedProps.isPlaying ?
                        <ButtonPlay onClick={() => setPlaying(!playing)}>{playing ? <PauseCircleIcon /> : <PlayCircleIcon />}</ButtonPlay>
                        : null}
                    <ButtonBack>
                        <ChevronLeftIcon />
                    </ButtonBack>
                    <DotGroup />
                    <ButtonNext>
                        <ChevronRightIcon />
                    </ButtonNext>
                </Grid>
            ) : null}
        </CarouselProvider>
    );
};
